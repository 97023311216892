var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-screen container mx-auto min-contents" }, [
    _c("div", { staticClass: "w-full rounded overflow-hidden my-4" }, [
      _c("div", { staticClass: "px-6 py-4" }, [
        _c("h2", { staticClass: "font-bold text-2xl text-center mb-4" }, [
          _vm._v(_vm._s(_vm.$t("pages.help.about.title"))),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "container mb-24" }, [
      _vm._m(0),
      _vm._m(1),
      _c("div", { staticClass: "mb-6" }, [
        _c("p", { staticClass: "text-lg font-bold" }, [
          _c("span", { staticClass: "text-2xl mx-3 text-yellow-400" }, [
            _vm._v("■"),
          ]),
          _vm._v(" " + _vm._s(_vm.$t("components.pulldownModal.menu.help"))),
        ]),
      ]),
      _c("ul", { staticClass: "ml-0 md:ml-8" }, [
        _c(
          "li",
          { staticClass: "mb-3" },
          [
            _c(
              "router-link",
              {
                staticClass:
                  "block text-sm font-semibold text-gray-700 underline",
                attrs: { to: "/faq/faq-product-regist/", target: "_blank" },
              },
              [
                _vm._v(
                  " ＞" +
                    _vm._s(_vm.$t("components.pulldownModal.help.guide")) +
                    " "
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "mb-3" },
          [
            _c(
              "router-link",
              {
                staticClass:
                  "block text-sm font-semibold text-gray-700 underline",
                attrs: {
                  to: "/help/" + _vm.simplifiedLoginUserRole,
                  target: "_blank",
                },
              },
              [
                _vm._v(
                  " ＞" +
                    _vm._s(_vm.$t("components.pulldownModal.help.manual")) +
                    " "
                ),
              ]
            ),
          ],
          1
        ),
        _c("li", { staticClass: "mb-3" }, [
          _c(
            "a",
            {
              staticClass:
                "block text-sm font-semibold text-gray-700 underline",
              attrs: { href: "/faq", target: "_blank" },
            },
            [
              _vm._v(
                " ＞" +
                  _vm._s(_vm.$t("components.pulldownModal.help.faq")) +
                  " "
              ),
            ]
          ),
        ]),
        _c("li", { staticClass: "mb-3" }, [
          _c(
            "a",
            {
              staticClass:
                "block text-sm font-semibold text-gray-700 underline",
              attrs: { href: "/words", target: "_blank" },
            },
            [
              _vm._v(
                " ＞" +
                  _vm._s(_vm.$t("components.pulldownModal.help.dictionary")) +
                  " "
              ),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", { staticClass: "mb-16" }, [
        _c("div", { staticClass: "mb-6" }, [
          _c("p", { staticClass: "text-lg font-bold" }, [
            _c("span", { staticClass: "text-2xl mx-3 text-yellow-400" }, [
              _vm._v("■"),
            ]),
            _vm._v(" ご利用にあたって"),
          ]),
        ]),
        _c("div", { staticClass: "ml-0 md:ml-8" }, [
          _c("p", { staticClass: "mb-0" }, [
            _vm._v(
              "弊社のオンラインフィルムマーケットは、オンライン上のプラットフォームサービスとなります。"
            ),
          ]),
          _c("p", { staticClass: "mb-0" }, [
            _vm._v(
              " これは、可能なかぎり人を介さずに海外との売買を成立させることで、"
            ),
            _c("br"),
            _vm._v(
              "通常の代理店では取り扱えない小規模な契約を成立させ、日本映画の海外でのチャンスを拡大し、"
            ),
            _c("br"),
            _vm._v(
              "またそれによって、ライセンサー様に少しでも多くの利益をお届けることを目的としております。 "
            ),
          ]),
          _c("p", { staticClass: "mb-0" }, [
            _vm._v(
              " 結果としてのべ多数百作品以上を世界に提供できており、お蔭様で一定の成功を収めていると考えております。 "
            ),
          ]),
          _c("p", { staticClass: "mb-6" }, [
            _vm._v(
              " これを継続したビジネスとして成立させるために、可能なかぎりコストを抑える必要があり、"
            ),
            _c("br"),
            _vm._v(
              "お客様とのやりとりを基本的にはシステムエラー、使い方などに限らせていただいております。 "
            ),
          ]),
          _c("p", { staticClass: "mb-0" }, [
            _vm._v(
              "ご利用にあたりましては、この点、ご理解をいただければ幸いです。"
            ),
          ]),
          _c("p", { staticClass: "mb-3" }, [
            _vm._v(
              " その上で、素材に関するご質問や、お電話でのご相談をご機能のお客様には、"
            ),
            _c("br"),
            _vm._v(
              "別途有償でのサービスを提供させていただいておりますので、ご検討いただければと思います。よろしくお願いいたします。 "
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", { staticClass: "mb-16" }, [
        _c("div", { staticClass: "mb-6" }, [
          _c("p", { staticClass: "text-lg font-bold" }, [
            _c("span", { staticClass: "text-2xl mx-3 text-yellow-400" }, [
              _vm._v("■"),
            ]),
            _vm._v(" オンラインフィルムマーケットとは？ "),
          ]),
        ]),
        _c("div", { staticClass: "ml-0 md:ml-8" }, [
          _c("p", { staticClass: "mb-3" }, [
            _c("span", { staticClass: "mr-2" }, [_vm._v("●")]),
            _vm._v(
              "オンラインフィルムマーケットとは、フィルミネーションが提供する、映画や番組の版権販売のためのプラットフォームのことです。 "
            ),
          ]),
          _c("p", { staticClass: "mb-3" }, [
            _c("span", { staticClass: "mr-2" }, [_vm._v("●")]),
            _vm._v("作品は、日本語だけでOKです。"),
          ]),
          _c("p", { staticClass: "mb-3" }, [
            _c("span", { staticClass: "mr-2" }, [_vm._v("●")]),
            _vm._v(
              "商品登録後、バイヤーからオファーが来ても、断ることができます。 "
            ),
          ]),
          _c("p", { staticClass: "mb-3" }, [
            _c("span", { staticClass: "mr-2" }, [_vm._v("●")]),
            _vm._v("販売が完了するまで登録は無料です。"),
            _c("br"),
            _vm._v("※販売成功時に、30%のFeeをいただいております。 "),
          ]),
          _c("p", { staticClass: "mb-3" }, [
            _c("span", { staticClass: "mr-2" }, [_vm._v("●")]),
            _vm._v(
              "弊社にアカウントがあるバイヤー様のみが、作品情報を閲覧できます。 "
            ),
          ]),
          _c("p", { staticClass: "mb-3" }, [
            _c("span", { staticClass: "mr-2" }, [_vm._v("●")]),
            _vm._v(
              "バイヤー様から注文が来た際に、日本語での条件交渉が可能です。"
            ),
          ]),
        ]),
        _c("div", { staticClass: "concept-label" }, [
          _c("img", {
            staticClass: "border border-gray-400 shadow-md my-6 max-w-4xl",
            attrs: {
              src: require("@/assets/image/help/faq/flow.jpg"),
              alt: "国内のライセンサー様は、作品登録、見積書作成、納品をすべて弊社のプラットフォーム上で、日本語だけで完結できます。あとの作業はフィルミネーションが行います。",
            },
          }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }